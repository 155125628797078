<template>
  <div class="forums__categories--container">
    <div v-if="isLoadingFlag" class="forums__categories--loader">
      <Loader />
    </div>
    <div class="accordion forums-accordion" role="tablist" v-else>
      <div
        class="forums__all--posts"
        :class="{ active: !getTopicId }"
        @click="setAllPostsView"
      >
        <img
          :src="
            !getTopicId
              ? require(`@/assets/images/vle/forums/all-post.svg`)
              : require(`@/assets/images/vle/forums/all-post-black.svg`)
          "
          alt="all posts"
        />
        <span>{{ $t("discussions.post.all_posts") }}</span>
      </div>
      <b-card
        no-body
        v-for="(item, index) in showDiscussionTopics"
        :key="item.name"
        :class="{
          active: item.id === getTopicId,
          'no__sub--category': item.id !== null
        }"
      >
        <b-card-header
          header-tag="header"
          class="p-1"
          role="tab"
          @click="handleMainCategoryClick(item)"
          v-b-toggle="`accordion-${index}`"
        >
          <img
            :src="
              item.id === getTopicId
                ? require(`@/assets/images/vle/forums/category-white.svg`)
                : require(`@/assets/images/vle/forums/category.svg`)
            "
            alt="category"
          />
          <b-button :title="item.name">{{ item.name }}</b-button>
          <img
            :src="require(`@/assets/images/vle/chevron-black.svg`)"
            alt="arrow"
            class="arrow"
            v-if="item?.categories.length > 0"
          />
          <div class="post-count" v-else>
            {{ item.post_count }}
            <span v-if="item?.unread_count > 0" class="unread-dot"></span>
          </div>
        </b-card-header>
        <b-collapse
          class="card__body--container"
          :id="`accordion-${index}`"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body
            v-for="(smallList, sindex) in item.categories"
            :key="sindex"
            @click.stop="handleSubCategoryClick(item, smallList, index)"
            :class="{
              active: smallList.id === getTopicId
            }"
          >
            <b-card-text :title="smallList.name">{{
              smallList.name
            }}</b-card-text>
            <div class="post-count">
              {{ smallList.post_count }}
              <span
                v-if="smallList?.unread_count > 0"
                class="unread-dot"
              ></span>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loader from "@/components/WrapperComponents/Loader.vue";
import discussionViewSelectorMixin from "@/core/mixins/discussionViewSelector.js";
export default {
  mixins: [discussionViewSelectorMixin],
  props: {
    isLoadingFlag: Boolean
  },
  components: { Loader },
  data() {
    return {
      parentObj: null
    };
  },
  computed: {
    ...mapGetters([
      "showDiscussionTopics",
      "getTopicId",
      "getSubcategoryActive"
    ])
  },
  created() {
    this.setAllPostsActiveMenu();
  },
  watch: {
    showDiscussionTopics(data) {
      this.getTopicIdfromlist(data);
      document
        .getElementById(`accordion-${this.getSubcategoryActive}`)
        ?.setAttribute(
          "class",
          "card__body--container add__active-subcategory"
        );
    }
  },
  methods: {
    setAllPostsActiveMenu() {
      if (!this.$route.params.topic_id && this.$route.name !== "AddPost") {
        this.$store.commit("SET_ALL_POSTS_ACTIVE", "");
      }
    },
    setAllPostsView() {
      this.$router
        .push({
          path: `/self-paced/${this.$route.params.id}/forums`
        })
        .catch(err => err);
    },
    getTopicIdfromlist(data) {
      data.forEach(item => {
        if (item.id === this.getTopicId) {
          this.parentObj = item;
          this.$store.commit("SET_TOPIC_NAME", { categoryName: item.name });
        }
        item.categories.forEach(category => {
          if (category.id === this.getTopicId) {
            this.$store.commit("SET_TOPIC_NAME", {
              categoryName: item.name,
              subCategoryName: category.name
            });
          }
        });
      });
      return null;
    },
    handleMainCategoryClick(item) {
      this.$store.commit("SET_SUB_CATEGORY_ACTIVE", "");
      if (item.categories && item.categories.length === 0) {
        this.$router
          .push({
            path: `/self-paced/${this.$route.params.id}/forums/${item.id}/view`
          })
          .catch(err => err);
      }
      if (item.categories && item.categories.length === 0) {
        this.$router
          .push({
            path: `/self-paced/${this.$route.params.id}/forums/${item.id}/view`
          })
          .catch(err => err);
      }
      if (!item.categories.length) {
        this.$store.commit("SET_TOPIC_ID", item.id);
        this.$store.commit("SET_TOPIC_NAME", { categoryName: item.name });
      }
    },
    handleSubCategoryClick(parentItem, item, index) {
      this.$router
        .push({
          path: `/self-paced/${this.$route.params.id}/forums/${item.id}/view`
        })
        .catch(err => err);
      this.$store.commit("SET_SUB_CATEGORY_ACTIVE", index);
      this.$store.commit("SET_IS_SEARCH_ACTIVE", false);
      this.$store.commit("SET_TOPIC_ID", item.id);
      this.$store.commit("SET_TOPIC_NAME", {
        categoryName: parentItem.name,
        subCategoryName: item.name
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.forums__categories--loader {
  height: 300px;
}
.forums-accordion {
  .card {
    background-color: $brand-neutral-0;
    border: 1px solid $brand-neutral-200;
    border-radius: 8px;
    overflow: visible;

    &:not(:first-child) {
      margin-top: 16px;
    }

    &.no__sub--category {
      .card__body--container {
        display: none;
        visibility: hidden;
        padding: 0;
        margin: 0;
        height: 0;
      }
    }

    &.active {
      background-color: $brand-primary;

      .btn {
        color: $brand-neutral-0;
      }
    }
  }

  .card-header {
    border: none;
    background-color: transparent;
    padding: 8px 16px !important;
    display: flex;
    align-items: center;
    margin: 0 !important;
    cursor: pointer;

    .btn {
      background-color: transparent;
      border: none;
      @include button-label;
      text-transform: none;
      letter-spacing: 0.15px;
      color: $brand-neutral-900;
      padding: 0;
      margin-left: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }

    .arrow {
      margin-left: auto;
    }

    &.not-collapsed {
      .arrow {
        transform: rotate(-180deg);
      }
    }

    .post-count {
      margin-left: auto;
    }
  }

  .card__body--container {
    padding: 0px 16px 8px;

    .card-body {
      border-radius: 8px;
      padding: 3px 4px 3px 32px;
      cursor: pointer;
      @include horizontal-space-between;

      &:not(:first-child) {
        margin-top: 8px;
      }

      &:hover {
        background-color: $brand-primary-75;

        .card-text {
          text-decoration: underline;
        }
      }

      &.active {
        background-color: $brand-primary;

        .card-text {
          color: $brand-neutral-0;
        }
      }
    }

    .card-text {
      @include body-medium;
      color: $brand-neutral-700;
      margin: 0;
      padding-right: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .post-count {
    padding: 4px;
    border-radius: 4px;
    background-color: $brand-primary-100;
    @include label-medium(500);
    color: $brand-primary;
    display: flex;
    align-items: center;

    .unread-dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $brand-primary-400;
      margin-left: 4px;
      display: block;
    }
  }
}
.forums__all--posts {
  background-color: $brand-neutral-0;
  border: 1px solid $brand-neutral-200;
  border-radius: 8px;
  overflow: visible;
  @include button-label;
  text-transform: none;
  letter-spacing: 0.15px;
  color: $brand-neutral-900;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    margin-left: 8px;
  }

  img {
    width: 24px;
  }

  &.active {
    color: $brand-neutral-0;
    background-color: $brand-primary;
  }
}
.add__active-subcategory {
  display: block !important;
}

[dir="rtl"] {
  .forums-accordion {
    .card-header {
      .post-count {
        margin-left: 0;
        margin-right: auto;
      }
      .btn {
        margin-left: 0px;
        margin-right: 8px;
      }
    }
  }
  .forums__all--posts span {
    margin-left: 0px;
    margin-right: 8px;
  }
}
</style>
